import { css } from '@emotion/react';

export const pulseAnimation = (background = '#FFFFFF') => css`
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 6px;
    background: ${background};
  }

  &::before {
    z-index: -2;
  }

  &::after {
    transform-origin: center;
    z-index: -1;
    animation: pulse 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  &:hover::after {
    animation: none;
  }

  @keyframes pulse {
    75%,
    100% {
      transform: scaleX(1.07) scaleY(1.4);
      opacity: 0;
    }
  }
`;
