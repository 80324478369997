import styled from '@emotion/styled';
import MUIButton from '@mui/material/Button';
import { mediaMax, breakpoints, COLORS } from 'styles';
import { pulseAnimation } from 'styles/animations';

export default styled(MUIButton)<{ pulse?: boolean }>`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  background: var(--qr-global-color-yellow);
  border-radius: 6px;
  font-style: normal;
  font-weight: var(--qr-global-font-weight-bold);
  font-size: var(--qr-global-font-size-button-medium);
  line-height: 130%;
  text-transform: none;
  color: var(--qr-global-color-white);

  &.PrimaryButtonWithArrow {
    span {
      position: relative;

      &::after {
        content: '';
        width: 16px;
        height: 18px;
        display: block;
        position: absolute;
        right: -42px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 15.754 16.997'%3E%3Cg transform='translate(-6 -5.379)'%3E%3Cpath d='M7.5,18H20.254' transform='translate(0 -4.123)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' /%3E%3Cpath d='M18,7.5l6.377,6.377L18,20.254' transform='translate(-4.123)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' /%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
      }
    }
  }

  &:hover {
    background: var(--qr-global-color-yellow);
    box-shadow: 0px 6px 20px rgba(84, 56, 13, 0.16);
  }

  :disabled {
    border-color: var(--qr-global-color-paused);
    background: var(--qr-global-color-paused);
    color: var(--qr-global-color-white);
  }

  ${mediaMax(breakpoints.tabletXS)} {
    font-weight: var(--qr-global-font-weight-bold);
    font-size: var(--qr-global-font-size-button-medium);
    line-height: 130%;
  }

  ${mediaMax(breakpoints.mobileMS - 1)} {
    font-size: var(--qr-global-font-size-button-default);
  }

  ${mediaMax(breakpoints.mobileS)} {
    font-size: var(--qr-global-font-size-default);
  }

  ${({ pulse }) => pulse && pulseAnimation(COLORS.YELLOW500)}
`;
